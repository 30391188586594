<div class="relative py-6 md:py-9">
  <scroll-snap [scrollSnapConfig]="scrollSnapConfig" [arrayData]="sliders">
    <div #slideItem *ngFor="let item of sliders" [id]="item.id" class="slide-item px-3 md:px-4">
      <ng-container *ngIf="item.target; then thenTemplate; else elseTemplate"></ng-container>
      <ng-template #thenTemplate>
        <a [href]="item.href" [target]="'_blank'">
          <img [ngSrc]="item.src" [priority]="true" width="1200" height="630" style="aspect-ratio: 40/21" class="w-[85vw] md:w-[65vw] max-w-none"
            [alt]="item.alt" />
        </a>
      </ng-template>
      <ng-template #elseTemplate>
        <a [routerLink]="item.href" [target]="'_self'">
          <img [ngSrc]="item.src" [priority]="true" width="1200" height="630" style="aspect-ratio: 40/21" class="w-[85vw] md:w-[65vw] max-w-none"
            [alt]="item.alt" />
        </a>
      </ng-template>
    </div>
  </scroll-snap>
</div>

<a href="https://www.facebook.com/awread.vn/" target="_blank" rel="noopener noreferrer">
  <div role="alert" class="my-4 alert max-w-lg mx-auto text-xs flex pointer">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" class="stroke-info h-6 w-6 shrink-0">
      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
    </svg>
    <span>Hãy liên hệ fanpage để treo banner cho truyện của bạn nhé!</span>
  </div>
</a>