import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { of } from 'rxjs';
// ti le 9:4 1080:480
// target: '_blank', neu muon mo tab moi
const db = {
  normal: [
    {
      id: 'XG1eXO',
      href: '/b/XG1eXO',
      src: '/frontend-assets/banners/cho-nang-den-ben-anh.png',
      alt: 'Chờ nắng đến bên anh - Moon Light',
    },
    {
      id: 'py1j5boD3',
      href: '/b/py1j5boD3',
      src: '/frontend-assets/banners/thang-5-cua-meo-con.png',
      alt: 'Tháng 5 của mèo con - Cẩm Thương',
    },
    {
      id: '39MYTAGgJ',
      href: '/b/39MYTAGgJ',
      src: '/frontend-assets/banners/em-dep-nhat-khi-mim-cuoi.png',
      alt: 'Em đẹp nhất khi mỉm cười - Quách Thái Di',
    },
    {
      id: 'Xfqim3',
      href: '/b/Xfqim3',
      src: '/frontend-assets/banners/trang-dan-loi.png',
      alt: 'Trăng dẫn lối - Meeanleen',
    },

    {
      id: '6rWpyj',
      href: '/b/6rWpyj',
      src: '/frontend-assets/banners/sieu-nhien-nghi-thuc-mau-cinis.png',
      alt: 'Siêu nhiên: nghi thức máu - Cinis',
    },
    {
      id: 'zzd7OZ',
      href: '/b/zzd7OZ',
      src: '/frontend-assets/banners/vu-khuc-bo-cong-anh.png',
      alt: 'Vũ khúc bồ công anh - TrinaJane',
    },





    // {
    //   id: '11',
    //   href: '/b/tuHsH9',
    //   src: '/frontend-assets/banners/tinh-doi-ta-nhu-giao-mua-xuan-ha.png',
    //   alt: 'Tình đôi ta như giao mùa xuân hạ - Yang Nana',
    // },
    // {
    //   id: '2',
    //   href: '/b/6TFKws',
    //   src: '/frontend-assets/banners/nu-chinh-bi-hoang-tuong.png',
    //   alt: 'Nữ chính bị hoang tưởng - Nam Á',
    // },
    // {
    //   id: '33',
    //   href: '/b/J5iIf9',
    //   src: '/frontend-assets/banners/toi-chi-muon-chet-gia.png',
    //   alt: 'Tôi chỉ muốn chết già - Nam Á',
    // },
    // {
    //   id: '44',
    //   href: '/b/RVboDl',
    //   src: '/frontend-assets/banners/ki-tuc-xa.png',
    //   alt: 'Kí túc xá đại học A - Phong Miên',
    // },
    // {
    //   id: '4',
    //   href: '/b/fwQzoe',
    //   src: '/frontend-assets/banners/pha-duyen-che-tinh.png',
    //   alt: 'Pha duyên chế tình - POUROURFATE - Mộc (Talia)',
    // },

    // {
    //   id: '6',
    //   href: '/b/CXYQsz',
    //   src: '/frontend-assets/banners/bach-y-duoi-khom-quynh-hoa.png',
    //   alt: 'Bạch y dưới khóm quỳnh hoa - Trần Thư Ân',
    // },

  ] as any,
  adult: [

    // {
    //   id: '7',
    //   href: '/b/AkQDZY',
    //   src: '/frontend-assets/banners/su-xao-dong-trong-tam-hon.png',
    //   alt: 'Sự xáo động trong tâm hồn - Nam Á',
    // },
    // {
    //   id: '88',
    //   href: '/b/uVvkrz',
    //   src: '/frontend-assets/banners/do-choi-cua-dai-tieu-thu.png',
    //   alt: 'Đồ chơi của đại tiểu thư - Ayato Yuri',
    // },
    // {
    //   id: '88',
    //   href: '/b/ylaAX3Hxp',
    //   src: '/frontend-assets/banners/trung-khuc.png',
    //   alt: 'Trung khúc dưới tà huân - Yang nana',
    // },
    {
      id: 'Gmra_0',
      href: '/b/Gmra_0',
      src: '/frontend-assets/banners/cui-minh-hon-em.png',
      alt: 'Cúi mình hôn em - Meeanleen',
    },
    {
      id: 'S7Tli9ikc',
      href: '/b/S7Tli9ikc',
      src: '/frontend-assets/banners/loi-thi-tham-cua-binh-minh.png',
      alt: 'Lời thì thầm của bình minh - Cẩm Thương',
    },
    {
      id: 'zzd7OZ',
      href: '/b/zzd7OZ',
      src: '/frontend-assets/banners/vu-khuc-bo-cong-anh.png',
      alt: 'Vũ khúc bồ công anh - TrinaJane',
    },
  ] as any,

};

@Injectable({ providedIn: 'root' })
export class SliderApi {
  constructor(private apollo: Apollo) { }

  normalBanner() {
    const shuffledDesktop = [...db.normal]; // Create a copy of the original array
    for (let i = shuffledDesktop.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1)); // Random index from 0 to i
      [shuffledDesktop[i], shuffledDesktop[j]] = [shuffledDesktop[j], shuffledDesktop[i]]; // Swap elements
    }
    return shuffledDesktop // Return the shuffled array
  }

  adultBanner() {
    const shuffledDesktop = [...db.adult]; // Create a copy of the original array
    for (let i = shuffledDesktop.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1)); // Random index from 0 to i
      [shuffledDesktop[i], shuffledDesktop[j]] = [shuffledDesktop[j], shuffledDesktop[i]]; // Swap elements
    }
    return shuffledDesktop // Return the shuffled array
  }

}
