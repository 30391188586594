import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Input, inject, OnChanges, SimpleChanges } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SliderFacade } from '@awread/core/slider';
import { ScrollSnapComponent } from '@awread/global/packages';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
  standalone: true,
  selector: 'atom-slider',
  imports: [CommonModule, RouterModule, FontAwesomeModule, ScrollSnapComponent, NgOptimizedImage],
  templateUrl: './slider.atom.html',
  styles: [
    `
      :host {
        display: block;
      }
      .slide-item {
        scroll-snap-align: center;
      }
    
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliderAtom implements OnChanges {
  sliderFacade = inject(SliderFacade);
  @Input() adult = false;
  @Input() changeValue: any = undefined;
  @Input() sliders = this.adult ? this.sliderFacade.adultBanner() : this.sliderFacade.normalBanner();
  @Input() scrollSnapConfig = {
    navigation: {
      usingNavigation: true,
      location: {
        locationArrowLeft: 'left-4 md:left-8',
        locationArrowRight: 'right-4 md:right-8',
      },
      style: 'w-6 h-6 md:w-8 md:h-8',
    },
    dot: {
      usingDot: true,
      type: 'normal',
      location: 'absolute -bottom-2 md:-bottom-2 left-1/2 -translate-x-1/2',
      style: {
        dotActive: 'bg-blue-500',
        dotNormal: 'bg-gray-300',
        shape: 'w-7 md:w-9 h-2 md:h-3 md:rounded-sm cursor-pointer',
      },
      text: {
        firstText: '',
        textDefault: '',
      },
    },
  };

  ngOnChanges(changes: SimpleChanges) {
    this.sliders = this.adult ? this.sliderFacade.adultBanner() : this.sliderFacade.normalBanner();
    console.log('ngOnChanges', changes)
  }


}
